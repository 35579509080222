import React from "react"
import { graphql } from "gatsby"

import { useBreakpoint } from "gatsby-plugin-breakpoints"
import { useTranslation } from 'gatsby-plugin-react-i18next'

import Layout from '../components/layout/layout'
import Seo from '../components/seo'
import MenuGlobal from '../components/menu'
import NotFound from '../components/notFound'

import '../sass/app.scss';

const NotFoundPage = ({ location }) => {

  const { t } = useTranslation()
  const data = t("global", { returnObjects: true })
  const breakpoints = useBreakpoint();

  return (
    <Layout mobile={useBreakpoint().mobile} post={null} data={data} noFooter={true} page={data.notfound.page}>
      <Seo seo={data.notfound.seo} />

      {breakpoints.mobile ? <MenuGlobal video src="https://res.cloudinary.com/dersmcqwc/video/upload/v1687958107/Videos/404_vers%C3%A3o_mobile_ja9yms.mp4"
        global={data} mobile noFilter location={location} noScroll />
        : <MenuGlobal video src="https://res.cloudinary.com/dersmcqwc/video/upload/v1687953590/Videos/404_vers%C3%A3o_desktop_soy4de.mp4"
          global={data} noFilter location={location} noScroll />
      }
        <NotFound data={data.notfound} />
    </Layout>
  )
}

export default NotFoundPage


export const pageQuery = graphql`
  query($language: String!) {
    locales: allLocale(filter: {ns: {in: ["global", "homepage"]}, language: {eq: $language}}) {
        edges {
          node {
            ns
            data
            language
          }
        }
    }
  }
`